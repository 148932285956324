$(function () {
    let dateLoad = new URLSearchParams(window.location.search).get('date')
    $("#datepicker").datepicker({
        autoclose: true,
        todayHighlight: true,
    }).datepicker('update', dateLoad ?? new Date());
});
$('.today-button').on('click', function () {
    let today = new Date(),
        month = (parseInt(today.getMonth()) + 1).toString()
    date = convertDate(today.getFullYear() + '/' + month + '/' + today.getDate());
    filterDate(date)
})
$('.tomorrow-button').on('click', function () {
    let today = new Date(),
        tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000)),
        month = (parseInt(tomorrow.getMonth()) + 1).toString()
    date = convertDate(tomorrow.getFullYear() + '/' + month + '/' + tomorrow.getDate());
    filterDate(date)
})
$('.select-date-button').on('click', function () {
    let month =( parseInt($('#datepicker').datepicker('getDate').getMonth()) + 1).toString()
    date = convertDate($('#datepicker').datepicker('getDate').getFullYear() + '/' + month + '/' + $('#datepicker').datepicker('getDate').getDate());
    filterDate(date)
})
function filterDate (date) {
    window.location.href = window.location.href.indexOf('?date=')!== -1 ? window.location.href.split('=')[0] + '=' + date : window.location.href + '?date=' + date
}
function convertDate (date) {
    let listItem = date.split('/');
    listItem = listItem.map(function (item) {
        return item.toString().length === 1 ? '0' + item.toString() : item.toString();
    });
    return listItem.join('/');
}

$('.station-select').select2();


$(document).ready(function(){
    let seatRemain = $('#seatRemain').val();

    if (seatRemain == 0) {
        $(".bus-seats:checkbox:not(:checked)").prop('disabled', true);
    }

    $('.bus-seats').on('change', function () {
        let seatRemainText = $('#seatRemainText');
        let selectedSeatLength = $('.bus-seats:checkbox:checked').length;
        seatRemainText.text(seatRemain - selectedSeatLength);
        if (seatRemain - selectedSeatLength == 0) {
            $(".bus-seats:checkbox:not(:checked)").prop('disabled', true);
        } else {
            $(".bus-seats:checkbox:not(:checked)").prop('disabled', false);
        }
    });

    $('#resetSetSeat').on('click', function () {
        $(".bus-seats:checkbox:checked").prop('checked', false);
        $('#seatRemainText').text(seatRemain);
        if (seatRemain == 0) {
            $(".bus-seats:checkbox:not(:checked)").prop('disabled', true);
        } else {
            $(".bus-seats:checkbox:not(:checked)").prop('disabled', false);
        }
    });

    if ($('#startStation') && $('#stopStation')) {
        const startStation = $('#startStation');
        const stopStation = $('#stopStation');
        startStation.on('change', function () {
            const position = $(this).find(':selected').data('position');
            const listStopPosition = stopStation.children('option');
            listStopPosition.each(function () {
                const stopPosition = $(this).data('position');
                if (stopPosition <= position) {
                    $(this).attr("disabled", "disabled");
                    $(this).removeAttr("selected");
                } else {
                    $(this).removeAttr("disabled");
                }
            })
            stopStation.select2();
        })
    }
});

$('.first-modal-button-yes').on('click', function () {
    const idItem = $(this).data('id');
    $(`#confirmDelete${idItem}`).modal('hide');
    $(`#confirmDelete${idItem}-${idItem}`).modal('show');
})

$('input[name="schedule_id"]').on('change', function () {
    const selectedDate = $(this).data('schedule-date') ?? '';
    $('#selectedSchedule').text(selectedDate);
    $('#selectedScheduleInput').val(selectedDate);
    $(this).parents('.data-schedule').siblings('.route-data-hide').attr('checked', true)
});

$('.first-modal-button-yes-multiple').on('click', function () {
    $('#confirmDeleteMultiple').modal('hide');
    $('#confirmDeleteSecond').modal('show');
})

$('.checkbox-delete').on('change', function () {
    $(`#itemDelete-${$(this).attr('id')}`).remove()
    if ($(this).is(':checked')) {
        $('.box-multiple-item').append(`<input hidden id="itemDelete-${$(this).attr('id')}" class="item-multiple-delete" value="${$(this).attr('id')}" name='ids[]'>`);
    }
    $(".button-delete-multiple").css('pointer-events', 'none')
    if ($(".box-multiple-item").children('.item-multiple-delete')?.length) {
        $(".button-delete-multiple").css('pointer-events', 'auto')
    }
})

$("#checkAll").change(function() {
    if(this.checked) {
        $(".checkbox-delete").prop('checked', true);
        $(".checkbox-delete").each(function (index, item) {
            $('.box-multiple-item').append(`<input hidden id="itemDelete-${item.id}" class="item-multiple-delete" value="${item.id}" name='ids[]'>`);
        })
    } else {
        $(".checkbox-delete").prop('checked', false);
        $(".checkbox-delete").each(function (index, item) {
            $(`#itemDelete-${item.id}`).remove()
        })
    }
    $(".button-delete-multiple").css('pointer-events', 'none')
    if ($(".box-multiple-item").children('.item-multiple-delete')?.length) {
        $(".button-delete-multiple").css('pointer-events', 'auto')
    }
});
$('input[name="start_time[0]"]').filter(':first').on('change', function () {
    let dateObj = new Date(),
        month = dateObj.getUTCMonth() + 1,
        day = dateObj.getUTCDate(),
        year = dateObj.getUTCFullYear(),
        date = new Date(year + "/" + month + "/" + day + ' ' + $(this).val()).subHours(1);
    const time = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
    $('input[name="booking_deadline"]').val(time)
})

Date.prototype.subHours= function(h){
    this.setTime(this.getTime() - (h*60*60*1000));
    return this;
}

$('#buttonSubmitReservation').on('click', function () {
    if ($(this).data('step') && $(this).data('step') === 'confirm') {
        $(this).css('opacity', 0.5).css('pointer-events', 'none')
    }
})

$('.btn-submit-form-delete').on('click', function () {
    $(this).css('opacity', 0.5).css('pointer-events', 'none')
})
